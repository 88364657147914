<template>
    <div class="Calendar">
        <v-calendar
            locale="ru"
            is-expanded
            v-model="selectedDate"
            :masks="mask"
            is-required
            :transition="'none'"
            :attributes="attributesCalendar"
            :max-date="maxDate"
            @update:from-page="updateDate($event)"
            @dayclick="dayclick($event)"
            ref="calendar" />
    </div>
</template>

<script>
export default {
    name: 'Calendar',
    props: {
        attributesCalendar: {
            type: Array,
            default: () => [],
        },

        сategories: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            from: new Date(),
            selectedDate: new Date(),
            date: '',
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD YY', // Uses 'iso' if missing
            },
            mask: {
                title: 'MMMM',
                weekdays: 'WW',
            },
        };
    },

    methods: {
        updateDate(date) {
            this.date = date;
            this.$emit('updateDate', date);
        },
        dayclick(date) {
            this.$emit('dayclick', date.id);
        },
    },
    computed: {
        maxDate() {
            let today = new Date();
            return new Date(today.getFullYear(), today.getMonth() + 1, 0);
        },
    },
    created() {},

    watch: {
        сategories() {
            let parms = this.date;
            // eslint-disable-next-line vue/no-mutating-props
            this.$emit('updateDate', parms);
        },
    },
};
</script>

<style lang="scss">
.Calendar {
    max-width: 390px;
    margin: 0 auto;
}
.vc-container {
    background: transparent;
    border: none;
    font-family: 'Inter';
    position: relative;

    .vc-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #897a6d;
    }
    .vc-header {
        margin-bottom: 16px;
    }
    .vc-arrow {
        width: 30px;
        height: 30px;
        color: #897a6c;
        svg {
            width: 25px;
            height: 23px;
        }
        &:focus,
        &:hover {
            background: #e2d1c3;
            color: #897a6c;
        }
    }
    .vc-weeks {
        background: linear-gradient(153.25deg, #ffffff 74.54%, rgba(255, 255, 255, 0) 116.31%);
        border-radius: 13px;
        padding: 0;
        position: relative;
        overflow: hidden;
        &::after {
            content: '';
            position: absolute;

            right: 0;
            bottom: 0;
            top: 0;
            width: 1px;
            background: linear-gradient(153.25deg, #ffffff 74.54%, rgba(255, 255, 255, 0) 116.31%);
            z-index: 1;
        }
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            height: 1px;
            left: 0;
            right: 0;
            background: #fcfbf9;
            z-index: 2;
        }
    }
    .vc-weekday {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        color: #897a6c;
        justify-content: center;

        padding: 10px 0;
        border-bottom: 1px solid #d8d8d8;
    }
    .vc-day {
        min-height: 70px;
        border-bottom: 1px solid #d8d8d8;
        border-right: 1px solid #d8d8d8;
        &:nth-child(6) {
            border-right: none;
        }
    }
    .vc-day.is-not-in-month * {
        opacity: 1;
        color: #d8d8d8;
    }
    .vc-popover-content-wrapper {
        display: none;
    }
    .vc-dot {
        width: 28px;
        height: 28px;
        background-color: transparent !important;
        margin-bottom: 10px;
        &:after {
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            background-size: 100% 100%;
        }
        &:nth-child(2):not(.menstruation) {
            width: 18px;
            height: 18px;
        }
        &:nth-child(3):not(.menstruation),
        &:nth-child(4):not(.menstruation),
        &:nth-child(5):not(.menstruation),
        &:nth-child(6):not(.menstruation) {
            display: none;
        }
        &.s-green {
            &:after {
                background-image: url(../assets/images/s-green.svg);
            }
        }
        &.s-dark {
            &:after {
                background-image: url(../assets/images/s-dark.svg);
            }
        }
        &.s-light {
            &:after {
                background-image: url(../assets/images/s-light.svg);
            }
        }
        &.mood-1 {
            &:after {
                background-image: url(../assets/images/icon-md-mood-01.svg);
            }
        }
        &.mood-2 {
            &:after {
                background-image: url(../assets/images/icon-md-mood-02.svg);
            }
        }
        &.mood-3 {
            &:after {
                background-image: url(../assets/images/icon-md-mood-03.svg);
            }
        }
        &.mood-4 {
            &:after {
                background-image: url(../assets/images/icon-md-mood-04.svg);
            }
        }
        &.mood-5 {
            &:after {
                background-image: url(../assets/images/icon-md-mood-05.svg);
            }
        }
        &.mood-6 {
            &:after {
                background-image: url(../assets/images/icon-md-mood-06.svg);
            }
        }
        &.mood-7 {
            &:after {
                background-image: url(../assets/images/icon-md-mood-07.svg);
            }
        }
        &.menstruation {
            width: 8px;
            height: 11px;
            position: absolute;
            top: 6px;
            right: 4px;
            &:after {
                background-image: url(../assets/images/icon-md-menstruation.svg);
            }
        }
    }

    .vc-day-content {
        font-weight: 600;
        display: flex;
        align-items: flex-start;
        text-align: center;

        color: #000000;
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: transparent !important;
        border-radius: 0;
        z-index: 1;
    }
    .vc-day.is-not-in-month * {
        color: #000000;
    }
    .vc-day.is-not-in-month {
        opacity: 0.5;
    }
}
</style>
