export default {
	methods: {
		closeTelegramWindow() {
			const tg = window.Telegram.WebApp;
			tg.close();
		},
		routeWithSameQuery(routeName) {
			const query = this.$route.query;
			this.$router.push({ name: routeName, query: query });
		},
	}
}