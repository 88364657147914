<template>
    <ul class="tabs">
        <li
            class="tab-btn"
            :class="{ active: selectId === item.id }"
            v-for="item in array"
            :key="item.id"
            @click="select(item.id)">
            {{ item.title }}
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        array: {
            type: Array,
            default: () => [],
        },
        selectIdIn: {
            type: [Number, String],
            default: null,
        },
    },
    data() {
        return {
            selectId: null,
        };
    },
    methods: {
        select(id) {
            this.selectId = id;

            this.$emit('input', this.selectId);
        },
    },
    mounted() {
        if (this.selectIdIn) {
            this.selectId = this.selectIdIn;
        }
    },
};
</script>

<style lang="scss" scoped>
.tabs {
    background: #ffffff;
    border-radius: 7px;
    padding: 2px;
    display: flex;
    width: min-content;
    margin: auto;

    .tab-btn {
        font-weight: 500;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: #e2d1c3;
        height: 22px;
        padding: 0 10px;
        min-width: 48px;
        cursor: pointer;
        user-select: none;
        background: #fff;
        border-radius: 7px;
        transition: all 0.15s ease;

        &.active {
            background: #e2d1c3;
            color: #897a6c;
        }
    }
}
</style>
